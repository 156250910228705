import {
  createTRPCProxyClient,
  createWSClient,
  loggerLink,
  wsLink,
  splitLink,
  httpBatchLink,
} from '@trpc/client';
import type {inferRouterOutputs} from '@trpc/server';
import superjson from 'superjson';
import type {AppRouter} from '@server-api-trpc/appRouter';
import Environment from '@/config/Environment';
import Urls from '@/config/Urls';

const wsClient = createWSClient({
  url: `${Urls.WebSocketServer}/trpc`,
});

// only used for test code
let cookieValue = '';

// need type annotation due to composite typescript project referencing server project
export const trpc: ReturnType<typeof createTRPCProxyClient<AppRouter>> =
  createTRPCProxyClient<AppRouter>({
    links: [
      // loggerLink({
      //   enabled: (opts) =>
      //     (Environment.IsLocal || Environment.IsCI) &&
      //     opts.direction === 'down' &&
      //     opts.result instanceof Error,
      // }),
      splitLink({
        condition(op) {
          return op.type === 'subscription';
        },
        true: wsLink({
          client: wsClient,
        }),
        false: httpBatchLink({
          url: `${Urls.ApiServer}/trpc`,
          headers() {
            if (typeof window === 'undefined') {
              return {
                Cookie: cookieValue,
              };
            }
            return {};
          },
          async fetch(url, options) {
            const response = await fetch(url, {
              ...options,
              credentials: 'include',
            });
            if (typeof window === 'undefined') {
              // we're running this in tests, which don't have cookie management. manually maintain them
              const setCookieHeader = response.headers.get('set-cookie');
              if (setCookieHeader) {
                cookieValue = setCookieHeader
                  .split('SameSite=Strict,')
                  .map((value) => {
                    return value.split(';')[0];
                  })
                  .join(';');
              }
            }
            return response;
          },
        }),
      }),
    ],
    /**
     * @link https://tanstack.com/query/v5/docs/reference/QueryClient
     */
    // queryClientConfig: {
    //   defaultOptions: {
    //     queries: {staleTime: 60},
    //   },
    // },
    transformer: superjson,
    //   };
    // },
  });

// TODO remove this; this is just here to debug websocket connections
trpc.debug.randomNumber.subscribe(undefined, {});

export type RouterOutputs = inferRouterOutputs<AppRouter>;
